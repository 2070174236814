import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import "./styles/main.sass"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

// Fix vh units on mobile:
function setVH() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
}
setVH()
window.addEventListener("resize", setVH)

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
