import { useRef, useEffect, useCallback, useState } from "react"
import Canvas2SVG from "./canvas2svg"

// Based on https://github.com/quietshu/apple-pencil-safari-api-test

function getCoordinates(
  e: MouseEvent & TouchEvent,
  canvas: HTMLCanvasElement
): { x: number; y: number; pressure: number } {
  let pressure = 0.1
  let x: number
  let y: number
  if (
    e.touches &&
    e.touches[0] &&
    typeof e.touches[0]["force"] !== "undefined"
  ) {
    if (e.touches[0]["force"] > 0) {
      pressure = e.touches[0]["force"]
    }
    x = e.touches[0].pageX - canvas.offsetLeft
    y = e.touches[0].pageY - canvas.offsetTop
  } else {
    pressure = 0.2
    x = e.pageX - canvas.offsetLeft
    y = e.pageY - canvas.offsetTop
  }

  return { x, y, pressure }
}

const useDrawingBoard = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const canvasCtxRef = useRef<CanvasRenderingContext2D>()
  const points = useRef<Array<{ x: number; y: number; lineWidth: number }>>([])
  const canvas2svgRef = useRef<Canvas2SVG>()
  const lineWidth = useRef<number>(0)
  const isMousedown = useRef<boolean>(false)
  const erasing = useRef<boolean>(false)
  const [isNotEmpty, setIsNotEmpty] = useState(false)

  const maxLineWidth = 20

  const withContext = useCallback(
    (cb: (ctx: CanvasRenderingContext2D) => void) => {
      if (canvasCtxRef.current && canvas2svgRef.current) {
        cb(canvasCtxRef.current)
        cb(canvas2svgRef.current as any)
      }
    },
    [canvasCtxRef, canvas2svgRef]
  )

  const resize = useCallback(() => {
    if (!canvasRef.current || !canvas2svgRef.current || !canvasCtxRef.current) {
      return
    }

    const ctx = canvasCtxRef.current

    let currentImage: ImageData | undefined
    if (ctx.canvas.width > 0) {
      currentImage = ctx.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      )
    }

    const newWidth = (canvasRef.current.parentNode as HTMLDivElement)
      .offsetWidth

    // I honestly have no idea why the canvas needs to be four pixels shorter than it's parent, but it's apparently the only way to eliminate scrollbars.
    const newHeight =
      (canvasRef.current.parentNode as HTMLDivElement).offsetHeight - 4

    const ratio = window.devicePixelRatio

    if (newWidth >= ctx.canvas.width && newHeight >= ctx.canvas.height) {
      ctx.canvas.width = newWidth * ratio
      ctx.canvas.style.width = `${newWidth}px`
      ctx.canvas.height = newHeight * ratio
      ctx.canvas.style.height = `${newHeight}px`
      ctx.scale(ratio, ratio)

      canvas2svgRef.current.setDimensions(newWidth, newHeight)

      if (currentImage) {
        ctx.putImageData(currentImage, 0, 0)
      }
    }
  }, [canvasRef, canvas2svgRef, canvasCtxRef])

  const setColor = useCallback(
    (color: string) => {
      withContext(ctx => {
        ctx.globalCompositeOperation = "source-over"
        ctx.fillStyle = color
        ctx.strokeStyle = color
        ctx.lineCap = "round"
      })
    },
    [withContext]
  )

  const setEraser = useCallback(
    (eraser: boolean) => {
      withContext(ctx => {
        erasing.current = eraser
        ctx.fillStyle = "white"
        ctx.strokeStyle = "white"
        ctx.lineCap = "round"
        ctx.globalCompositeOperation = "destination-out"
      })
    },
    [withContext, erasing]
  )

  const eraseAll = useCallback(() => {
    withContext(ctx => {
      ctx.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height)
    })
    canvas2svgRef.current = new Canvas2SVG({
      width: canvasRef.current!.width,
      height: canvasRef.current!.height
    })
    setIsNotEmpty(false)
  }, [withContext])

  const startLine = useCallback(
    (e: MouseEvent & TouchEvent) => {
      const { x, y, pressure } = getCoordinates(e, canvasRef.current!)

      isMousedown.current = true

      lineWidth.current = Math.log(pressure + 1) * maxLineWidth

      withContext(ctx => {
        ctx.lineWidth = lineWidth.current
        ctx.beginPath()
        ctx.moveTo(x, y)
      })

      points.current.push({ x, y, lineWidth: lineWidth.current })
    },
    [isMousedown, lineWidth, points, withContext, canvasRef]
  )

  const continueLine = useCallback(
    (e: MouseEvent & TouchEvent) => {
      if (!isMousedown.current) {
        return
      }
      e.preventDefault()

      const { x, y, pressure } = getCoordinates(e, canvasRef.current!)

      // smoothen line width
      lineWidth.current =
        Math.log(pressure + 1) * maxLineWidth * (erasing.current ? 1 : 0.2) +
        lineWidth.current * 0.8
      points.current.push({ x, y, lineWidth: lineWidth.current })

      if (points.current.length >= 3) {
        var l = points.current.length - 1
        var xc = (points.current[l].x + points.current[l - 1].x) / 2
        var yc = (points.current[l].y + points.current[l - 1].y) / 2

        withContext(ctx => {
          ctx.lineWidth = points.current[l - 1].lineWidth
          ctx.quadraticCurveTo(
            points.current[l - 1].x,
            points.current[l - 1].y,
            xc,
            yc
          )
          ctx.stroke()
          ctx.beginPath()
          ctx.moveTo(xc, yc)
        })
      }
    },
    [lineWidth, points, erasing, withContext]
  )

  const endLine = useCallback(
    (e: MouseEvent & TouchEvent) => {
      const { x, y } = getCoordinates(e, canvasRef.current!)

      isMousedown.current = false

      if (points.current.length >= 3) {
        var l = points.current.length - 1

        withContext(ctx => {
          ctx.quadraticCurveTo(points.current[l].x, points.current[l].y, x, y)
          ctx.stroke()
        })
      }

      points.current = []
      lineWidth.current = 0

      setIsNotEmpty(true)
    },
    [isMousedown, lineWidth, points, withContext, setIsNotEmpty]
  )

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }

    canvasCtxRef.current = canvasRef.current!.getContext("2d")!

    canvas2svgRef.current = new Canvas2SVG({
      width: canvasRef.current.width,
      height: canvasRef.current.height
    })

    resize()
    setColor("black")
    ;["touchstart", "mousedown"].forEach(name =>
      canvasRef.current?.addEventListener(name as any, startLine)
    )
    ;["touchmove", "mousemove"].forEach(name =>
      canvasRef.current?.addEventListener(name as any, continueLine)
    )
    ;["touchend", "touchleave", "mouseup"].forEach(name =>
      canvasRef.current?.addEventListener(name as any, endLine)
    )
    ;(window as any).canvas2svg = canvas2svgRef.current

    window.addEventListener("resize", resize)
    return () => window.removeEventListener("resize", resize)
  }, [canvasRef, startLine, continueLine, endLine, setColor, setEraser, resize])

  return {
    canvasRef,
    canvas2svgRef,
    setColor,
    setEraser,
    eraseAll,
    resize,
    isNotEmpty
  }
}

export default useDrawingBoard
