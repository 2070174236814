import React, { useState, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import ReactGA from "react-ga"
import cards from "./cards"
import { createCard } from "./api"
import Navigation from "./Navigation"

const NewCard = () => {
  const [name, setName] = useState("")
  const [loading, setLoading] = useState(false)
  const { designId } = useParams()
  const history = useHistory()

  const postCard = useCallback(() => {
    async function create() {
      if (designId && cards.hasOwnProperty(designId)) {
        setLoading(true)
        const { id } = await createCard({
          illustration: designId,
          message: cards[designId].text!,
          recipient: name
        })
        ReactGA.event({ category: "Cards", action: "create", label: designId })
        history.push(`/cards/sign/${id}`)
      }
    }
    create()
  }, [name, setLoading, history, designId])

  return (
    <div className="recipient container">
      <Navigation currentStep="cards" />

      <main className="card-artboard">
        <div className="instructions">
          <div className="wrapper">
            {designId && (
              <div className="card">
                <img src={cards[designId].path} alt={cards[designId].alt} />
              </div>
            )}
            <h2>Who is this card for?</h2>
            <form
              onSubmit={e => {
                e.preventDefault()
                postCard()
              }}
            >
              <input
                className="form-input"
                type="text"
                placeholder="Name"
                value={name}
                onChange={e => setName(e.currentTarget.value)}
              />
            </form>
          </div>
        </div>
        <div className="next-step">
          <small>Next:</small>
          <button
            onClick={postCard}
            className="button next"
            disabled={loading || name.length === 0}
          >
            {loading
              ? "Creating card..."
              : name.length === 0
              ? "Waiting for a name..."
              : "I’m ready to sign!"}
          </button>
        </div>
      </main>
    </div>
  )
}
export default NewCard
