import React, { useEffect, useState } from "react"
import { Card } from "./types"
import { getCard } from "./api"

export interface CardLoaderProps {
  cardId?: string
  render?: (card: Card) => React.ReactElement
  renderLoading?: () => React.ReactElement
}

const CardLoader: React.FC<CardLoaderProps> = ({
  cardId,
  render,
  renderLoading
}) => {
  const [card, setCard] = useState<Card | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchCard() {
      if (cardId) {
        const card = await getCard(cardId)
        setCard(card)
        setLoading(false)
      }
    }
    fetchCard()
  }, [cardId])

  if (loading && renderLoading) {
    return renderLoading()
  } else if (card && render) {
    return render(card)
  }
  return null
}

export default CardLoader
