import bigfoot from "./card-bigfoot.png"
import cheese from "./card-cheese.png"
import condolences from "./card-condolences.svg"
import congrats from "./card-congrats.svg"
import flan from "./card-flan.svg"
import getWell from "./card-get-well.svg"
import happy from "./card-happy.svg"
import pickle from "./card-pickle.png"
import sriracha from "./card-sriracha.png"
import tomHanks from "./card-t-hanks.png"
import thanks from "./card-thanks.svg"
import thinking from "./card-thinking.svg"
import cheers from "./card-cheers.png"
import cupcake from "./card-cupcake.png"
import cow from "./card-cow-moon.png"
import bird from "./card-bird-heart.png"
import looking from "./card-looking.svg"
import pizza from "./card-pizza.svg"
import yetisSnowman from "./card-yetis-snowman.png"
import snowman from "./card-snowman.png"
import happyHolidays from "./card-holidays.png"
import dumpster from "./card-2020.png"
import balloon from "./card-balloon.png"
import goat from "./card-goat.svg"
import groundhog from "./card-groundhog.png"
import wavelength from "./card-wavelength.svg"
import spreadsheet from "./card-spreadsheet.svg"

import bigfootSocial from "./card-bigfoot-social.jpg"
import cheeseSocial from "./card-cheese-social.jpg"
import condolencesSocial from "./card-condolences-social.jpg"
import congratsSocial from "./card-congrats-social.jpg"
import flanSocial from "./card-flan-social.jpg"
import getWellSocial from "./card-get-well-social.jpg"
import happySocial from "./card-happy-social.jpg"
import pickleSocial from "./card-pickle-social.jpg"
import srirachaSocial from "./card-sriracha-social.jpg"
import tomHanksSocial from "./card-t-hanks-social.jpg"
import thanksSocial from "./card-thanks-social.jpg"
import thinkingSocial from "./card-thinking-social.jpg"
import cheersSocial from "./card-cheers-social.jpg"
import cupcakeSocial from "./card-cupcake-social.jpg"
import cowSocial from "./card-cow-moon-social.jpg"
import birdSocial from "./card-bird-heart-social.jpg"
import lookingSocial from "./card-looking-social.jpg"
import pizzaSocial from "./card-pizza-social.jpg"
import yetisSnowmanSocial from "./card-yetis-snowman-social.jpg"
import snowmanSocial from "./card-snowman-social.jpg"
import happyHolidaysSocial from "./card-holidays-social.jpg"
import dumpsterSocial from "./card-2020-social.jpg"
import balloonSocial from "./card-balloon-social.jpg"
import goatSocial from "./card-goat-social.jpg"
import groundhogSocial from "./card-groundhog-social.jpg"
import wavelengthSocial from "./card-wavelength-social.jpg"
import spreadsheetSocial from "./card-spreadsheet-social.jpg"

const cards: {
  [key: string]: {
    path: string
    socialPath: string
    type:
      | "congratulations"
      | "thanks"
      | "encouragement"
      | "birthday"
      | "get-well"
      | "condolences"
      | "because"
      | "holiday"
      | "farewell"
    text: string
    alt: string
    isAvailable: boolean
  }
} = {
  spreadsheet: {
    path: spreadsheet,
    socialPath: spreadsheetSocial,
    type: "encouragement",
    text: "We can always COUNT() on you!",
    alt: "“You’re a spreadsheet superhero!” above a happy spreadsheet wearing a cape",
    isAvailable: true
  },
  wavelength: {
    path: wavelength,
    socialPath: wavelengthSocial,
    type: "thanks",
    text: "Thanks for being such a great friend.",
    alt: "Isn’t it fun that we’re always on the same wavelength?",
    isAvailable: true
  },
  groundhog: {
    path: groundhog,
    socialPath: groundhogSocial,
    type: "holiday",
    text: "Happy Groundhog Day!",
    alt: "a groundhog pokes its head out of hole in the snow; in the ground, little flags that say “Phil”; the groundhog says “Sorry, all this ground is mine.”",
    isAvailable: false
  },
  goat: {
    path: goat,
    socialPath: goatSocial,
    type: "encouragement",
    text: "You’re just the greatest (of all time).",
    alt: "a goat yelling AAAAAAHHHH!!",
    isAvailable: true
  },
  balloon: {
    path: balloon,
    socialPath: balloonSocial,
    type: "farewell",
    text: "",
    alt: "a hot air balloon floats above the clouds with a banner that says “Good luck on your next adventure",
    isAvailable: true
  },
  dumpster: {
    path: dumpster,
    socialPath: dumpsterSocial,
    type: "holiday",
    text: "Cheers to a new year!",
    alt: "a 2020 flaming dumpster roasting marshmallows on itself",
    isAvailable: true
  },
  yetisSnowman: {
    path: yetisSnowman,
    socialPath: yetisSnowmanSocial,
    type: "holiday",
    text: "Happy holidays!",
    alt: "two yetis building a snowman",
    isAvailable: false
  },
  snowman: {
    path: snowman,
    socialPath: snowmanSocial,
    type: "holiday",
    text: "Wishing you some peaceful time reflecting this holiday season.",
    alt: "a snowman gazes into a hand mirror and asks “What am I?”",
    isAvailable: false
  },
  happyHolidays: {
    path: happyHolidays,
    socialPath: happyHolidaysSocial,
    type: "holiday",
    text: "",
    alt: "Happy holidays hand-written with some stars/snowflakes surrounding it",
    isAvailable: false
  },
  flan: {
    path: flan,
    socialPath: flanSocial,
    type: "congratulations",
    text: "You’re flantastic!",
    alt: "a smiling flan on a plate",
    isAvailable: true
  },
  tomHanks: {
    path: tomHanks,
    socialPath: tomHanksSocial,
    type: "thanks",
    text: "We just wanted to say T.hanks!",
    alt: "Tom Hanks",
    isAvailable: true
  },
  bigfoot: {
    path: bigfoot,
    socialPath: bigfootSocial,
    type: "encouragement",
    text: "We believe in you!",
    alt: "a winking Bigfoot gives a thumbs up from behind a tree",
    isAvailable: true
  },
  cheese: {
    path: cheese,
    socialPath: cheeseSocial,
    type: "birthday",
    text:
      "We don’t know about you, but we’re really excited that cheese increases.",
    alt:
      "a fancy cheese-headed man with mustache and monocle smokes a pipe in a library, text says “They say wisdom increases with age, like cheese”",
    isAvailable: true
  },
  pickle: {
    path: pickle,
    socialPath: pickleSocial,
    type: "encouragement",
    text: "If we were ever in a pickle, we know who we’d relish being with.",
    alt: "a pickle, text says “We think you’re a big dill",
    isAvailable: true
  },
  sriracha: {
    path: sriracha,
    socialPath: srirachaSocial,
    type: "encouragement",
    text: "We think you make everything you’re part of better.",
    alt:
      "an excited bottle of sriracha, text says “Are you a giant bottle of sriracha?”",
    isAvailable: true
  },
  happy: {
    path: happy,
    socialPath: happySocial,
    type: "birthday",
    text: "Happy birthday!",
    alt: "HAPPY",
    isAvailable: true
  },
  pizza: {
    path: pizza,
    socialPath: pizzaSocial,
    type: "thanks",
    text: "We hope this isn’t too cheesy.",
    alt:
      "stacked pizza boxes with flying slices of pizza, text says “You always deliver”",
    isAvailable: true
  },
  thanks: {
    path: thanks,
    socialPath: thanksSocial,
    type: "thanks",
    text: "You are so appreciated!",
    alt: "Thanks! Thanks! Thanks!",
    isAvailable: true
  },
  condolences: {
    path: condolences,
    socialPath: condolencesSocial,
    type: "condolences",
    text: "We’re here for you.",
    alt: "We don’t have the right words <3",
    isAvailable: true
  },
  thinking: {
    path: thinking,
    socialPath: thinkingSocial,
    type: "because",
    text: "",
    alt: "Thinking of you.",
    isAvailable: true
  },
  getWell: {
    path: getWell,
    socialPath: getWellSocial,
    type: "get-well",
    text: "",
    alt: "Feel better soon",
    isAvailable: true
  },
  cheers: {
    path: cheers,
    socialPath: cheersSocial,
    type: "congratulations",
    text: "",
    alt: "Cheers!",
    isAvailable: true
  },
  cupcake: {
    path: cupcake,
    socialPath: cupcakeSocial,
    type: "birthday",
    text: "Happy birthday!",
    alt: "a cupcake with a lit birthday candle and confetti",
    isAvailable: true
  },
  cow: {
    path: cow,
    socialPath: cowSocial,
    type: "encouragement",
    text: "You got this.",
    alt: "a cow is weight training with a poster that says “Shoot for the moon”",
    isAvailable: true
  },
  bird: {
    path: bird,
    socialPath: birdSocial,
    type: "because",
    text: "Thinking of you",
    alt: "two birds are building a bird house with a large heart inside",
    isAvailable: true
  },
  looking: {
    path: looking,
    socialPath: lookingSocial,
    type: "because",
    text: "Thinking of you",
    alt: "a yeti is on another yeti’s shoulders and looking through a monocular",
    isAvailable: true
  },
  congrats: {
    path: congrats,
    socialPath: congratsSocial,
    type: "congratulations",
    text: "You did it!",
    alt: "Congrats",
    isAvailable: true
  }
}

export default cards
