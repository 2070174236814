import { useCallback } from "react"

const useClipboard = () => {
  const copyToClipboard = useCallback(async (value: string) => {
    try {
      if ("clipboard" in navigator) {
        await (navigator as any).clipboard.writeText(value)
      } else {
        const el = document.createElement("textarea")
        el.style.fontSize = "12pt"
        // Reset box model
        el.style.border = "0"
        el.style.padding = "0"
        el.style.margin = "0"
        // Move element out of screen horizontally
        el.style.position = "absolute"
        el.style.left = "-9999px"
        // Move element to the same position vertically
        const yPosition =
          window.pageYOffset || document.documentElement.scrollTop
        el.style.top = `${yPosition}px`
        document.body.appendChild(el)
        el.setAttribute("readonly", "")
        el.value = value
        el.select()
        document.execCommand("copy")
        document.body.removeChild(el)
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  return copyToClipboard
}

export default useClipboard
