import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"

const BaseOpenGraph: React.FC<{
  title?: string
  description?: string
  imageUrl?: string
  url?: string
}> = ({ title, description, imageUrl, url = "" }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {title && (
        <meta name="twitter:title" property="og:title" content={title} />
      )}
      {description && (
        <meta
          property="og:description"
          name="twitter:description"
          content={description}
        />
      )}
      <meta property="og:url" content={`https://wegotchu.cards${url}`} />
      <meta property="twitter:url" content={`https://wegotchu.cards${url}`} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Helmet>
  )
}

const LocationAwareOpenGraph: React.FC<{
  title?: string
  description?: string
  imageUrl?: string
}> = ({ title, description, imageUrl }) => {
  const { pathname } = useLocation()

  return (
    <BaseOpenGraph
      title={title}
      description={description}
      imageUrl={imageUrl}
      url={pathname}
    />
  )
}

export { LocationAwareOpenGraph, BaseOpenGraph }
