import React, { useRef, useState, useCallback } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import ReactGA from "react-ga"
import cards from "./cards"
import useDrawingBoard from "./useDrawingBoard"
import { uploadFile, createSignature } from "./api"
import Navigation from "./Navigation"
import CardLoader from "./CardLoader"
import { LocationAwareOpenGraph } from "./OpenGraph"

const COLORS = {
  BLACK: "black",
  RED: "hsl(342, 100%, 45%)",
  GREEN: "hsl(142, 47%, 50%)",
  BLUE: "hsl(195, 100%, 46%)",
  PINK: "hsl(326, 94%, 48%)",
  YELLOW: "hsl(45.6, 100%, 50%)",
  ERASER: "--ERASER--"
}

function svgStringToElement(svg: string) {
  const template = document.createElement("template")
  template.innerHTML = svg.trim()
  return template.content.firstChild as SVGSVGElement
}

function trimWhitespace(svg: SVGSVGElement): SVGSVGElement {
  const container = document.createElement("div")
  container.setAttribute(
    "style",
    "position:absolute; visibility:hidden; width:0; height:0"
  )
  container.appendChild(svg)
  document.body.appendChild(container)

  const g = container.querySelector("svg")!.querySelector("g")
  if (g) {
    const gbox = g.getBBox()
    svg.setAttribute(
      "viewBox",
      [gbox.x - 10, gbox.y - 10, gbox.width + 20, gbox.height + 20].join(" ")
    )
    svg.setAttribute("width", gbox.width.toString())
    svg.setAttribute("height", gbox.height.toString())
  }

  document.body.removeChild(container)
  return svg
}

const SignCard = () => {
  const { search } = useLocation()
  const { cardId } = useParams()
  const history = useHistory()
  const signOnly = new URLSearchParams(search).has("signOnly")

  const [loading, setLoading] = useState(false)
  const [currentColor, setCurrentColor] = useState(COLORS.BLACK)
  const imgRef = useRef<HTMLImageElement>(null)
  const {
    canvasRef,
    canvas2svgRef,
    setColor,
    setEraser,
    eraseAll,
    resize,
    isNotEmpty
  } = useDrawingBoard()
  const [signatureType, setSignatureType] = useState("image")
  const [text, setText] = useState("")
  const [ackInstructions, setAckInstructions] = useState(false)

  const switchColor = useCallback(
    (color: string) => {
      if (color === COLORS.ERASER) {
        setEraser(true)
      } else {
        setEraser(false)
        setColor(color)
      }
      setCurrentColor(color)
    },
    [setCurrentColor, setColor, setEraser]
  )

  const clearCanvas = useCallback(() => {
    eraseAll()
  }, [eraseAll])

  const uploadSignature = useCallback(() => {
    async function upload() {
      if (!cardId) {
        return
      }

      if (signatureType === "image" && canvas2svgRef.current) {
        const svgString = canvas2svgRef.current!.getSerializedSvg()
        const resizedSvg = trimWhitespace(svgStringToElement(svgString))
        const drawingData = new Blob([resizedSvg.outerHTML], {
          type: "image/svg+xml"
        })
        setLoading(true)
        const url = await uploadFile(drawingData)

        await createSignature(cardId, { type: "image", url })
        ReactGA.event({ category: "Cards", action: "sign", label: "drawing" })
        history.push(`/cards/share/${cardId}${signOnly ? "?signOnly" : ""}`)
      } else if (signatureType === "text" && text) {
        setLoading(true)
        await createSignature(cardId, { type: "text", value: text })
        ReactGA.event({ category: "Cards", action: "sign", label: "text" })
        history.push(`/cards/share/${cardId}${signOnly ? "?signOnly" : ""}`)
      }
    }

    upload()
  }, [canvas2svgRef, cardId, signOnly, signatureType, text, history])

  return (
    <div className="sign container">
      <Navigation currentStep="sign" cardId={cardId} signOnly={signOnly} />
      <main className="card-artboard">
        <div className="sign-artboard">
          {!ackInstructions && (
            <div className="sign-instructions">
              <h2>This canvas is all yours.</h2>
              <p>Type a message or sign your name.</p>
              <small>(we’ll add it to the card for you)</small>
              <button
                className="button"
                onClick={() => {
                  setAckInstructions(true)
                  setTimeout(() => {
                    resize()
                    switchColor(currentColor)
                  }, 0)
                }}
              >
                Got it!
              </button>
            </div>
          )}
          <div
            className="canvas-container"
            style={{
              display:
                ackInstructions && signatureType === "image" ? "block" : "none"
            }}
          >
            <canvas ref={canvasRef} />
          </div>
          <div
            className="textarea-container"
            style={{
              display:
                ackInstructions && signatureType === "text" ? "block" : "none"
            }}
          >
            <textarea
              rows={8}
              className="form-input"
              placeholder="Type a message here"
              maxLength={500}
              onChange={e => setText(e.currentTarget.value)}
              value={text}
            />
            <small>max 500 characters ({500 - text.length} remaining)</small>
          </div>
        </div>
        <div className="sign-sidebar">
          <CardLoader
            cardId={cardId}
            renderLoading={() => <div>Loading...</div>}
            render={card => (
              <div className="card sidebar">
                <LocationAwareOpenGraph
                  title={`Sign a card for ${card.recipient}`}
                  description="A digital card to pass around and sign, even when we’re not together."
                  imageUrl={`https://wegotchu.cards${
                    cards[card.illustration].socialPath
                  }`}
                />
                <h2>
                  A card for:{" "}
                  <strong className="recipient">{card.recipient}</strong>
                </h2>
                <div className="card">
                  {card.signatures.length > 0 && (
                    <a
                      href={`/cards/view/${card.id}`}
                      className="view-card external"
                      target="_blank"
                    >
                      <span>Preview Card</span>
                    </a>
                  )}
                  <img
                    ref={imgRef}
                    src={cards[card.illustration].path}
                    alt={cards[card.illustration].alt}
                  />
                </div>
                <div className="message">{cards[card.illustration].text}</div>
              </div>
            )}
          />
          <div className="sign-controls">
            <button
              id="type"
              onClick={() => setSignatureType("text")}
              className={signatureType === "text" ? "selected" : ""}
            >
              Type something
            </button>
            <button
              id="draw"
              onClick={() => setSignatureType("image")}
              className={signatureType === "image" ? "selected" : ""}
            >
              Or Draw something
            </button>

            <div className="sign-colors">
              <button
                onClick={() => switchColor(COLORS.BLACK)}
                className={currentColor === COLORS.BLACK ? "selected" : ""}
                id="black"
              >
                black
              </button>
              <button
                onClick={() => switchColor(COLORS.RED)}
                className={currentColor === COLORS.RED ? "selected" : ""}
                id="red"
              >
                red
              </button>
              <button
                onClick={() => switchColor(COLORS.GREEN)}
                className={currentColor === COLORS.GREEN ? "selected" : ""}
                id="green"
              >
                green
              </button>
              <button
                onClick={() => switchColor(COLORS.BLUE)}
                className={currentColor === COLORS.BLUE ? "selected" : ""}
                id="blue"
              >
                blue
              </button>
              <button
                onClick={() => switchColor(COLORS.PINK)}
                className={currentColor === COLORS.PINK ? "selected" : ""}
                id="pink"
              >
                pink
              </button>
              <button
                onClick={() => switchColor(COLORS.YELLOW)}
                className={currentColor === COLORS.YELLOW ? "selected" : ""}
                id="yellow"
              >
                yellow
              </button>
            </div>
            <div className="sign-erase">
              <button
                onClick={() => switchColor(COLORS.ERASER)}
                className={currentColor === COLORS.ERASER ? "selected" : ""}
                id="eraser"
              >
                Eraser
              </button>
              <button
                disabled={currentColor !== COLORS.ERASER}
                id="erase-all"
                onClick={() => clearCanvas()}
              >
                All
              </button>
            </div>
          </div>
          <div className="next-step">
            <small>Next:</small>
            <button
              className="button next"
              disabled={
                loading || !ackInstructions || (!isNotEmpty && text === "")
              }
              onClick={() => {
                uploadSignature()
              }}
            >
              {loading ? "Signing card..." : "I’m done signing!"}
            </button>
          </div>
        </div>
      </main>
    </div>
  )
}

export default SignCard
