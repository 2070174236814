import React, { useState, useCallback } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import cards from "./cards"
import Navigation from "./Navigation"
import CardLoader from "./CardLoader"

import useClipboard from "./useClipboard"

const ShareCard = () => {
  const { search } = useLocation()
  const { cardId } = useParams()

  const signOnly = new URLSearchParams(search).has("signOnly")

  const shareUrl = `${window.location.protocol}//${window.location.host}/cards/sign/${cardId}?signOnly`
  const [copied, setCopied] = useState(false)
  const copyToClipboard = useClipboard()

  const copy = useCallback(async () => {
    await copyToClipboard(shareUrl)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [copyToClipboard, shareUrl])

  return (
    <div className="pass container">
      <Navigation currentStep="share" cardId={cardId} signOnly={signOnly} />
      <main className="card-artboard">
        <div className="instructions">
          <CardLoader
            cardId={cardId}
            renderLoading={() => <div>Loading...</div>}
            render={card => (
              <div className="wrapper">
                <div className="card">
                  {card.signatures.length > 0 && (
                    <a
                      href={`/cards/view/${card.id}`}
                      className="view-card external"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={cards[card.illustration].path}
                        alt={cards[card.illustration].alt}
                      />
                      <span>Preview Card</span>
                    </a>
                  )}
                </div>
                <p>
                  Copy and share this link so others can sign the card for{" "}
                  <strong>{card.recipient}</strong> too.
                </p>
                <div className="link-url" onClick={() => copy()}>
                  {shareUrl}
                </div>
                <small onClick={() => copy()}>
                  {copied ? "Copied!" : "Click to copy link"}
                </small>
              </div>
            )}
          />
        </div>
        <div className="next-step">
          <small>Next:</small>
          <Link
            className="button next"
            to={`/cards/send/${cardId}${signOnly ? "?signOnly" : ""}`}
          >
            Everyone’s done!
          </Link>
        </div>
      </main>
    </div>
  )
}

export default ShareCard
