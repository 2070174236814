import {
  Card,
  ProtoCard,
  ProtoSignature,
  Signature,
  UploadResponse,
  APIResponse
} from "../types"

const API_ENDPOINT = "https://api-6hz26dlusq-uc.a.run.app"

async function request<T extends Object>(
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<T> {
  const response = await fetch(input, init)
  const data = (await response.json()) as APIResponse | T
  if (data.hasOwnProperty("error")) {
    throw new Error(`error from api: ${(data as APIResponse).error}`)
  }

  return data as T
}

async function createCard(card: ProtoCard): Promise<Card> {
  const response = await request<Card>(`${API_ENDPOINT}/cards`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(card)
  })
  return response
}

async function getCard(id: string): Promise<Card> {
  const response = await request<Card>(`${API_ENDPOINT}/cards/${id}`)
  return response
}

async function createSignature(
  cardId: string,
  signature: ProtoSignature
): Promise<Signature> {
  const response = await request<Signature>(
    `${API_ENDPOINT}/cards/${cardId}/signatures`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(signature)
    }
  )
  return response
}

async function uploadFile(file: Blob): Promise<string> {
  const response = await request<UploadResponse>(`${API_ENDPOINT}/upload`, {
    method: "POST",
    headers: { "Content-Type": file.type },
    body: file
  })
  return response.url
}

export { createCard, getCard, createSignature, uploadFile }
