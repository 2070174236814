import React, { useEffect } from "react"
import { Switch, Route, useHistory } from "react-router-dom"
import ReactGA from "react-ga"
import ChooseCard from "./ChooseCard"
import NewCard from "./NewCard"
import SignCard from "./SignCard"
import ShareCard from "./ShareCard"
import SendCard from "./SendCard"
import ViewCard from "./ViewCard"
import Help from "./Help"
import Terms from "./Terms"
import Privacy from "./Privacy"
import { BaseOpenGraph } from "./OpenGraph"
import "./App.css"

ReactGA.initialize("UA-120806798-8")
ReactGA.pageview(window.location.pathname)

const App = () => {
  const history = useHistory()

  useEffect(() => {
    history.listen(location => {
      ReactGA.pageview(location.pathname)
    })
  }, [history])

  return (
    <>
      <BaseOpenGraph
        title="Wegotchu"
        description="Digital cards to pass around and sign, even when you’re not together."
        imageUrl="https://wegotchu.cards/social.jpg"
      />
      <Switch>
        <Route exact path="/">
          <ChooseCard />
        </Route>

        <Route exact path="/cards">
          <ChooseCard />
        </Route>

        <Route path="/cards/new/:designId">
          <NewCard />
        </Route>

        <Route path="/cards/sign/:cardId">
          <SignCard />
        </Route>

        <Route path="/cards/share/:cardId">
          <ShareCard />
        </Route>

        <Route path="/cards/send/:cardId">
          <SendCard />
        </Route>

        <Route path="/cards/view/:cardId">
          <ViewCard />
        </Route>

        <Route exact path="/help">
          <Help />
        </Route>

        <Route exact path="/terms">
          <Terms />
        </Route>

        <Route exact path="/privacy">
          <Privacy />
        </Route>
      </Switch>
    </>
  )
}

export default App
