import React from "react"
import { Link } from "react-router-dom"
import cards from "./cards"
import Navigation from "./Navigation"

const ChooseCard = () => {
  return (
    <div className="choose container">
      <Navigation currentStep="cards" />
      <main className="card-artboard">
        <div className="card-list">
          {Object.keys(cards).filter(c => cards[c].isAvailable).map(c => (
            <div key={c} className={`card ${cards[c].type}`}>
              <Link to={`/cards/new/${c}`}>
                <img src={cards[c].path} alt={cards[c].alt} />
              </Link>
              <div className="message">{cards[c].text || "Blank"}</div>
            </div>
          ))}
        </div>
      </main>
    </div>
  )
}

export default ChooseCard
