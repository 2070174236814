import React from "react"
import { Link } from "react-router-dom"

interface StepLink {
  id: string
  message: string
  route: (cardId?: string, signOnly?: boolean) => string
}

const STEP_LINKS: StepLink[] = [
  { id: "cards", message: "Choose a card", route: cardId => "/cards" },
  {
    id: "sign",
    message: "Sign it",
    route: (cardId, signOnly) =>
      `/cards/sign/${cardId || ""}${signOnly ? "?signOnly" : ""}`
  },
  {
    id: "share",
    message: "Pass it around",
    route: (cardId, signOnly) =>
      `/cards/share/${cardId || ""}${signOnly ? "?signOnly" : ""}`
  },
  {
    id: "send",
    message: "Send it",
    route: (cardId, signOnly) =>
      `/cards/send/${cardId || ""}${signOnly ? "?signOnly" : ""}`
  }
]

interface NavLinkProps {
  active: boolean
  current: boolean
  message: string
  route: string
}
const NavLink: React.FC<NavLinkProps> = props => {
  const classes = []
  if (props.active) {
    classes.push("active")
  }
  if (props.current) {
    classes.push("current")
  }
  if (props.active) {
    return (
      <Link className={classes.join(" ")} to={props.route}>
        {props.message}
      </Link>
    )
  } else {
    return <a className="inactive">{props.message}</a>
  }
}

export interface NavigationProps {
  currentStep: string
  cardId?: string
  hideSteps?: boolean
  signOnly?: boolean
}

const Navigation: React.FC<NavigationProps> = props => {
  let active = true
  let steps: any[] = []

  STEP_LINKS.forEach(step => {
    if (step.id === "cards" && props.signOnly) {
      return
    }

    const current = props.currentStep === step.id
    if (current) {
      // Anything past 'current' is inactive
      active = false
    }
    steps.push(
      <NavLink
        key={step.id}
        active={active || current}
        current={current}
        route={step.route(props.cardId, props.signOnly)}
        message={step.message}
      />
    )
  })

  return (
    <nav className="nav-main">
      <Link className="logo logo-wegotchu" to="/">
        Wegotchu
      </Link>
      <p>
        Digital cards to pass around and sign, even when you’re not together.
      </p>
      {!props.hideSteps && <div className="nav-main-steps">{steps}</div>}
      <small className="byline">
        <span>from your friends at </span>
        <a className="logo logo-andyet" href="https://andyet.com">
          &yet
        </a>
        <div className="helpline">
          <Link to="/help">Guide</Link>
          <Link to="/terms">Terms</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
      </small>
    </nav>
  )
}

export default Navigation
