import React from "react"
import Navigation from "./Navigation"
import { Link } from "react-router-dom"

const Privacy = () => {
  return (
    <div className="privacy secondary container">
      <Navigation currentStep="help" hideSteps />
      <main className="card-artboard">
        <div className="content-container">
          <h1 className="center">Privacy and Security</h1>

          <h2>About Wegotchu.cards</h2>

          <p>Wegotchu is a little passion project by &yet, created as a way for our remote team to regain the experience of passing and signing a card for a team mate. We love to help people connect and build things that delight with our design and development talents.</p>

          <p>We’re not here to resell information about you, or keep track of what you do online. We respect your privacy and security. This page describes how we put those values into practice.</p>

          <h2>Security</h2>

          <p>Wegotchu is built with standard web technologies: JavaScript, HTML, and CSS. We do our best to adhere to secure development practices using processes put in place by a world class security team. We do make use of several <Link to="/help">open source libraries</Link> which have been pressure tested in much larger production environments and benefit from existing in the open, available for widespread testing, review, and improvement.</p>

          <p>We take security very seriously, so if you have questions or find something we should know about, please see the “Reporting a bug” section below to contact us.</p>

          <h2>Privacy Policy</h2>

          <p>Our default privacy policy is never to gather or store information about you if we don’t need it to make Wegotchu work, or to engage in any other behavior that would compromise your privacy and security in any way.</p>

          <p>Your card is viewable to anyone with the URL.</p>

          <h2>Anonymous Metrics</h2>

          <p>We use Google Analytics to measure anonymous usage data (with no personally identifying information attached) to improve Wegotchu. Examples of usage data may include information about your browser, operating system, and time spent on Wegotchu.cards.</p>

          <h2>Reporting a bug</h2>

          <p>All security bugs in Wegotchu are taken seriously. Bugs or vulnerabilities should be reported by email to <a href="mailto:support@andyet.com">support@andyet.com</a>. Your email will be acknowledged within 24 hours.</p>

          <p>You will receive a more detailed response within 48 hours, which will also indicate the next steps we will take in handling your report. After our initial reply, our team will keep you informed of the progress being made toward a fix. As we move toward a formal announcement of the report and resolution, we may contact you for additional information surrounding the reported issue.</p>
        </div>
      </main>
    </div>
  )
}

export default Privacy
