import React from "react"
import Navigation from "./Navigation"

const Terms = () => {
  return (
    <div className="terms secondary container">
      <Navigation currentStep="help" hideSteps />
      <main className="card-artboard">
        <div className="content-container">
          <h1 className="center">Terms of Service</h1>

          <p>These Terms of Service constitute a legal agreement between you (“User”) and &yet, LLC (“&yet”), a Washington State Limited Liability Company. &yet provides a digital greeting card (“Card”) service (“Wegotchu”). By accessing the Wegotchu website or utilizing Wegotchu, User agrees to be bound by these terms and conditions. Your use of and access to Wegotchu is conditioned upon User’s compliance and acceptance of these terms.</p>

          <p>Wegotchu is not available to persons or entities who are not legally eligible to be bound by this Agreement. User represents that they are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this Agreement, and to abide by and comply with this Agreement.</p>

          <h2>Your Usage and Responsibilities</h2>

          <ol>
            <li>User Content: User agrees that &yet has no obligation with respect to any user content. Under no circumstances will &yet be liable in any way for any data or other content viewed while using Wegotchu. User agrees that they are solely responsible for the user content that they create, transmit, display, use, store and/or redistribute while using Wegotchu. User is solely responsible for any claims relating to their user content. User shall not communicate any message or material that is deemed harassing, threatening, indecent (including nudity, pornography, and sexually explicit content), obscene, slanderous, defamatory, or otherwise unlawful. Wegotchu is not designed or intended for the transmission of confidential or sensitive information and should not be used for any such purpose. User understands that any confidential or personally identifiable information transmitted utilizing Wegotchu may be viewed by others in the Wegotchu session.</li>
            <li>Copyright: User agrees that content may only be transmitted, displayed, used, redistributed or otherwise with the express consent of the owner of the relevant rights in the intellectual property. User will not use Wegotchu to infringe the intellectual property rights of others. User retains copyright and any other rights they already hold in content transmitted using Wegotchu. User understands and agrees that by transmitting content using Wegotchu, you automatically grant (and warrant and represent they have a right to grant) to &yet the right to forward and display the content to viewers of and others using the Wegotchu site.</li>
            <li>Digital Millennium Copyright Act: If you believe Wegotchu has been used in a manner that constitutes copyright infringement, you shall notify &yet at <a href="mailto:support@andyet.com">support@andyet.com</a>, and provide all of the following information, as required by the Digital Millennium Copyright Act ("DMCA"): (i) a statement that you have identified content in Wegotchu that infringes a copyright of a third party for whom you are authorized to act; (ii) a description of the copyrighted work you claim has been infringed; (iii) a specific description of where the allegedly infringing material is located in Wegotchu, including a URL or exact description of the content's location; (iv) your name, address, telephone number, and e-mail address; (v) a statement that you have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use); (vi) a statement that, under penalty of perjury, the information in your notice is accurate and that you are authorized to act on behalf of the owner of the exclusive right that is allegedly infringed; and (vii) your electronic or scanned physical signature. &yet reserves the right to delete or disable allegedly infringing content, to terminate the accounts of users who are repeat infringers, and to forward the information in the copyright-infringement notice to the user who allegedly provided the infringing content.</li>
            <li>Export and Other Laws: User agrees to comply with all applicable local, state, national and foreign laws, rules, and regulations applicable to their use of Wegotchu, including any laws regarding the export of data or software, and data sharing, data processing and data transfer laws and regulations.</li>
            <li>No Resale: Wegotchu may be used for business or consumer purposes only. User will not reproduce, resell, or distribute Wegotchu for any purpose unless User has been specifically permitted to do so under a separate agreement with &yet.</li>
            <li>Abuse: User agrees not to use Wegotchu in a manner that (i) abuses or materially disrupts any aspect of the networks, security systems, products and/or websites of &yet; (ii) intentionally distributes malware, viruses, or any other computer code, files or programs of a destructive or deceptive nature, (iii) interferes with the use of Wegotchu by other users; (iv) facilitates unsolicited and unauthorized advertising or marketing communications, including spam; (v) violates or facilitates the violation of the legal rights of others, and/or (v) otherwise constitutes abuse in &yet's sole discretion.</li>
            <li>Communications of Others: &yet assumes no responsibility or liability for violations of the above prohibitions by other users of Wegotchu. &yet may investigate any complaints and violations that come to its attention and may take any action that it believes is appropriate. &yet also reserves the right not to take any action.</li>
          </ol>

          <h2>Intellectual Property of &yet</h2>

          <p>User hereby acknowledges that &yet owns all legal rights, titles and interests, in and to Wegotchu and the Cards including any intellectual property rights which subsist in Wegotchu (whether those rights are registered or unregistered, and wherever in the world those rights may exist). &yet hereby grants you a limited, revocable, non-sublicensable license to display the Cards; provided, that you retain all copyright and other proprietary notices contained therein. User may not use any trade names, trademarks, service marks, and logos associated or displayed with Wegotchu without the prior written consent of &yet. User will not modify, adapt, translate, prepare derivative works from, decompile, reverse engineer, disassemble or otherwise attempt to derive source code from any of &yet’s services, software, or documentation, or create or attempt to create a substitute or similar service or product through use of or access to Wegotchu or proprietary information related thereto. User shall not use or register (or attempt to register) any trademarks (including domain names) that are derived from or confusingly similar to those of &yet or the Wegotchu service.</p>

          <h2>Liability, Warranty, Indemnification</h2>

          <ol>
            <li>Limitation of Liability: IN NO EVENT SHALL &YET BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, WHETHER OR NOT FORESEEABLE AND HOWEVER ARISING. THIS LIMITATION SHALL APPLY BUT NOT BE LIMITED TO LOST INCOME, REVENUE, GOODWILL, USE, OR OTHER INTANGIBLE LOSSES. IT WILL ALSO APPLY IRRESPECTIVE OF WHETHER THE LIABILITY ARISES UNDER CONTRACT, TORT, OR ANY OTHER THEORY. SOME JURISDICTIONS DO NOT ALLOW LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</li>
            <li>No Warranty: USER UNDERSTANDS AND AGREES THAT WEGOTCHU IS PROVIDED BY &YET AND ANY THIRD PARTY PROVIDERS OR LICENSORS ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND MAY CONTAIN BUGS, ERRORS, AND OTHER DEFECTS. YOUR ACCESS TO WEGOTCHU IS AT YOUR SOLE RISK. TO THE EXTENT PERMITTED BY APPLICABLE LAW, &YET EXPRESSLY DISCLAIMS ALL AND USER RECEIVES NO WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THOSE OF MERCHANTABILITY, SATISFACTORY QUALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. &YET MAKES NO WARRANTY THAT ANY OF THE SERVICES WILL MEET YOUR REQUIREMENTS AND/OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE, NOR DOES &YET MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR THE ACCURACY OF ANY OTHER INFORMATION OBTAINED THROUGH THE SERVICES OR THAT ANY DEFECTS IN THE SERVICES WILL BE CORRECTED. &YET MAKES NO WARRANTIES OR GUARANTEES REGARDING THE SECURITY OF THE SERVICES.</li>
            <li>Assumption of Risks and Costs: USER ASSUMES ALL RISKS AND ALL COSTS ASSOCIATED WITH YOUR USE OF THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY INTERNET ACCESS FEES, BACK-UP EXPENSES, COSTS INCURRED FOR THE USE OF YOUR DEVICE AND PERIPHERALS, AND ANY DAMAGE TO ANY SOFTWARE, INFORMATION, DATA OR EQUIPMENT. USER UNDERSTANDS AND AGREES THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED (INCLUDING PLUG-INS) THROUGH THE USE OF WEGOTCHU IS AT THEIR OWN RISK AND THAT THEY WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO THEIR COMPUTER SYSTEM AND/OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA. NO INFORMATION OR ADVICE, WHETHER ORAL OR WRITTEN, OBTAINED BY USER FROM &YET OR THROUGH WEGOTCHU SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES AND CONDITIONS, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.</li>
            <li>Indemnification: User shall indemnify, defend and hold &yet, its agents, affiliates, and licensors harmless from any claim, costs, losses, damages, liabilities, judgments and expenses (including reasonable fees of attorneys and other professionals), arising out of or in connection with any claim, action or proceeding (any and all of which are "Losses") arising out of or related to (1) any act or omission by User in using Wegotchu, (2) User’s breach of this Agreement, or (3) violation of laws, rules, and regulations in connection with User’s use of Wegotchu. At &yet’s option, User shall assume control of the defense and settlement of any Loss subject to indemnification by them (provided that, in such event, &yet may at any time thereafter elect to take over control of the defense and settlement of any such Loss, and in any event, User shall not settle any such Loss without &yet prior written consent).</li>
          </ol>

          <h2>Privacy</h2>
          <p>Use of Wegotchu is subject to the Privacy Policy located at <a href="https://wegotchu.cards/privacy">https://wegotchu.cards/privacy</a>, and which is incorporated into this Agreement by reference. This Privacy Policy reviews practices with regards to IP addresses, use of aggregated feedback on Wegotchu, and collection of technical data, usage and quality statistics and related information to improve Wegotchu.</p>

          <h2>Miscellaneous</h2>

          <ol>
            <li>Severability: In the case that any provision of this Agreement is held to be invalid or unenforceable, it will be enforced to the extent permitted by applicable law, and the remaining provisions of the Agreement shall remain in full force and effect.</li>
            <li>Interpretation: Any construction or interpretation to be made of the Agreement shall not be construed against the drafter. Any rights not expressly granted herein to the parties are reserved.</li>
            <li>Waiver: Failure by either party to exercise or delay in exercising any right, power or privilege under this Agreement shall not operate as a waiver; nor shall any single or partial exercise of any right, power or privilege preclude any other or further exercise thereof.</li>
            <li>Relationship: User and &yet shall be and act independently. This Agreement shall not be construed to create a partnership, joint venture, agent, employee, or employer relationship between the parties. User shall not have authority to assume or create obligations on behalf of &yet, and they shall not attempt to do so.</li>
            <li>Headings: The headings of the sections of this Agreement shall have no substantive effect and shall not enter into the interpretation of these sections.</li>
            <li>Choice of Law: The Agreement shall be governed by the laws of the State of Washington without regard to its conflict of law provisions. User and &yet agree to submit to the personal jurisdiction of the courts located within the county of Benton, Washington.</li>
          </ol>
        </div>
      </main>
    </div>
  )
}

export default Terms
