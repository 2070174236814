import React from "react"
import Navigation from "./Navigation"
import HelpChoose from "./images/help-choose.jpg"
import HelpName from "./images/help-name.jpg"
import HelpSign from "./images/help-sign.jpg"
import HelpPass from "./images/help-pass.jpg"
import HelpDone from "./images/help-done.jpg"
import HelpView from "./images/help-view.jpg"

const Help = () => {
  return (
    <div className="help secondary container">
      <Navigation currentStep="help" hideSteps />
      <main className="card-artboard">
        <div className="content-container">
          <h1 className="center">About Wegotchu</h1>
          <p>
            Wegotchu is a little passion project by{" "}
            <a href="https://andyet.com">&yet</a>, created as a way for our
            remote team to regain the experience of passing and signing a card
            for a team mate.
          </p>
          <p>
            While this is a just-for-fun project for us, we are very open to
            your thoughts and feedback.{" "}
            <a href="https://twitter.com/andyet">Tweet at us!</a>
          </p>
          <p>
            Wegotchu could not have been built without the following wonderful open source libraries:{" "}
            <a href="https://github.com/gliffy/canvas2svg">canvas2svg</a>{", "}
            <a href="https://github.com/JedWatson/classnames">classnames</a>{", "}
            <a href="https://github.com/reach/router">Reach Router</a>{", "}
            <a href="https://github.com/facebook/react">React</a>{", "}
            <a href="https://github.com/nfl/react-helmet">React Helmet</a>{", "}
            <a href="https://github.com/sass/dart-sass">Sass</a>{", "}
            <a href="https://github.com/microsoft/TypeScript">TypeScript</a>{", "}
          </p>
          <h2 className="center">How it works</h2>
          <p>Here’s a guide on how to use Wegotchu (plus some tips):</p>
        </div>
        <ol className="help-steps">
          <li>
            <p>First, choose a card.</p>
            <img src={HelpChoose} alt="a grid of cards" />
          </li>
          <li>
            <p>
              Type in the recipient’s name (a) and then click the button (b) to
              sign the card.
              <br />
              <br />
              <small>
                Tip: You can also hit ENTER to move on to the next step.
              </small>
            </p>
            <img
              src={HelpName}
              alt="the card you’ve chosen with Bigfoot on the front, a form input with the name “Bilbo” filled in, and a button that says “I’m ready to sign!”"
            />
          </li>
          <li>
            <p>
              Type a message -or- draw something on the canvas (a). Click the
              button (b) to move on.
              <br />
              <br />
              <small>Tip: Fill the entire canvas with your drawing.</small>
            </p>
            <img
              src={HelpSign}
              alt="a canvas and drawing tools and “BEORN” drawn on the canvas in pink"
            />
          </li>
          <li>
            <p>
              Copy the link (a) and share with others to sign the card. You can
              preview the card (b) too.
              <br />
              <br />
              Once everyone has signed, click the button (c) to get your card
              link.
              <br />
              <br />
              <small>
                Tip: People can still sign the card after you get your link.
              </small>
            </p>
            <img
              src={HelpPass}
              alt="the card you’ve signed, a URL for you to copy, and a button that says “Everyone's done!”"
            />
          </li>
          <li>
            <p>
              Copy the link to share the final card or click to view the card
              yourself.
            </p>
            <img
              src={HelpDone}
              alt="a blue page with the card you’ve signed, a new URL for you to copy, and a “View card” button"
            />
          </li>
          <li>
            <p>
              Everyone’s drawings and messages will show up on the card.
              <br />
              <br />
              <small>
                How many people can sign? 1-15 feels safe. If you see some wonky
                scaling, let us know please!
              </small>
            </p>
            <img
              src={HelpView}
              alt="the inside of the card says “We believe in you!” with signatures and messages from others like Smeagol and Frodo"
            />
          </li>
        </ol>
        <p className="center">
          Still need help? <a href="https://twitter.com/andyet">Tweet at us.</a>
        </p>
      </main>
    </div>
  )
}

export default Help
