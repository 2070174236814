import React, { useState } from "react"
import { useParams } from "react-router-dom"
import cards from "./cards"
import Navigation from "./Navigation"
import CardLoader from "./CardLoader"
import WrapAroundGridLayout from "./WrapAroundGridLayout"
import { LocationAwareOpenGraph } from "./OpenGraph"

const ViewCard = () => {
  const { cardId } = useParams()
  const [cardOpened, setCardOpened] = useState(false)

  return (
    <div className="view container slim">
      <Navigation currentStep="view" hideSteps cardId={cardId} />
      <main className="card-artboard">
        <CardLoader
          cardId={cardId}
          renderLoading={() => <div className="card-front">Loading...</div>}
          render={card => {
            if (!cardOpened) {
              return (
                <div className="card-front">
                  <LocationAwareOpenGraph
                    title={`${card.recipient}, wegotchu a card`}
                    description="A digital card we passed around and signed just for you."
                    imageUrl={`https://wegotchu.cards${
                      cards[card.illustration].socialPath
                    }`}
                  />
                  <h3>{card.recipient}, wegotchu a card.</h3>
                  <img
                    src={cards[card.illustration].path}
                    alt={cards[card.illustration].alt}
                  />
                  <button
                    onClick={() => setCardOpened(!cardOpened)}
                    className="button next"
                  >
                    {cardOpened ? "View front of card" : "Open card"}
                  </button>
                </div>
              )
            } else {
              return (
                <div className="card-open">
                  <LocationAwareOpenGraph
                    title={`${card.recipient}, wegotchu a card`}
                    description="A digital card we passed around and signed just for you."
                    imageUrl={`https://wegotchu.cards${
                      cards[card.illustration].socialPath
                    }`}
                  />
                  <div className="card-preview">
                    <small>A card for {card.recipient}</small>
                    <img
                      src={cards[card.illustration].path}
                      alt={cards[card.illustration].alt}
                    />
                    <button
                      onClick={() => setCardOpened(!cardOpened)}
                      className="button next"
                    >
                      {cardOpened ? "View front" : "Open card"}
                    </button>
                  </div>
                  <div className="card-inside">
                    {cards[card.illustration].text && (
                      <div className="card-message">
                        <h2>{cards[card.illustration].text}</h2>
                      </div>
                    )}
                    <div className="card-signatures-list">
                      {card.signatures.map(signature => (
                        <div className="card-signature" key={signature.id}>
                          {signature.type === "image" ? (
                            <img
                              src={signature.url}
                              alt="A signature on the card"
                            />
                          ) : (
                            <span>{signature.value}</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            }
          }}
        />
      </main>
    </div>
  )
}

export default ViewCard
