import React, { useState, useCallback } from "react"
import { useLocation, useParams } from "react-router-dom"
import cards from "./cards"
import Navigation from "./Navigation"
import CardLoader from "./CardLoader"
import useClipboard from "./useClipboard"

const SendCard = () => {
  const { search } = useLocation()
  const { cardId } = useParams()

  const signOnly = new URLSearchParams(search).has("signOnly")

  const shareUrl = `${window.location.protocol}//${window.location.host}/cards/view/${cardId}`
  const [copied, setCopied] = useState(false)
  const copyToClipboard = useClipboard()

  const copy = useCallback(async () => {
    await copyToClipboard(shareUrl)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [copyToClipboard, shareUrl])

  return (
    <div className="send container">
      <Navigation currentStep="send" cardId={cardId} signOnly={signOnly} />
      <main className="card-artboard">
        <div className="instructions">
          <CardLoader
            cardId={cardId}
            renderLoading={() => <div>Loading...</div>}
            render={card => (
              <div className="wrapper">
                <div className="card">
                  <a href={shareUrl} className="view-card">
                    <img
                      src={cards[card.illustration].path}
                      alt={cards[card.illustration].alt}
                    />
                    <span>View card</span>
                  </a>
                </div>
                <h2>
                  Your Wegotchu card is ready! Copy and share this link with{" "}
                  <strong>{card.recipient}</strong>.
                </h2>
                <div className="link-url" onClick={() => copy()}>
                  {shareUrl}
                </div>
                <small onClick={() => copy()}>
                  {copied ? "Copied!" : "Click to copy link"}
                </small>
              </div>
            )}
          />
        </div>
      </main>
    </div>
  )
}

export default SendCard
